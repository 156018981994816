<template>
  <div>
    <!-- Task Card Header -->
    <b-card class="card" title="Tambah Forum Kelas">
      <div class="mt-2">
        <b-row>
          <!-- Judul Forum -->
          <b-col lg="12 mb-1">
            <label class="d-block">Judul Forum</label>
            <b-form-input
              placeholder="Masukkan Judul Forum"
              v-model="formData.taskTitle"
              :state="formErrors.taskTitle.state"
            />
            <b-form-invalid-feedback v-show="!formErrors.taskTitle.state">
              {{ formErrors.taskTitle.message }}
            </b-form-invalid-feedback>
          </b-col>

          <!-- Deskripsi Forum -->
          <b-col lg="12 mb-1">
            <label class="d-block">Konten Forum</label>
            <quill-editor v-model="formData.taskDescription" />

            <b-form-invalid-feedback v-show="!formErrors.taskDescription.state">
              {{ formErrors.taskDescription.message }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>

        <!-- File Input -->
        <div>
          <label class="d-block">Lampirkan File</label>
          <div v-if="filelist.length === 0" class="drop-file-con">
            <b-card
              class="drop-file p-4 mt-2"
              @dragover="dragover"
              @dragleave="dragleave"
              @drop="drop"
            >
              <div class="d-flex flex-column align-items-center">
                <b-avatar size="lg" rounded="sm" variant="light-primary">
                  <i class="card-icon-lg ti ti-file-upload"></i>
                </b-avatar>
                <h3 class="pt-1 small-gap-bottom">
                  Seret file di sini
                </h3>
                <span class="small-gap-bottom">
                  (Format file yang didukung: .pdf, .doc, .docx, .xls, .xlsx,
                  .ppt, .pptx)
                </span>
                <b-button variant="primary" @click="$refs.file.click()">
                  Upload File</b-button
                >
              </div>
            </b-card>
          </div>

          <!-- Display Selected File -->
          <b-row v-show="filelist.length != 0">
            <b-col lg="2">
              <b-card
                class="border-light-secondary d-flex flex-column text-center"
                v-for="(file, index) in filelist"
                :key="index"
              >
                <b-button
                  class="remove-button-x"
                  variant="danger"
                  size="sm"
                  @click="remove(index)"
                >
                  <i class="ti ti-x"></i>
                </b-button>
                <b-avatar rounded :variant="fileType(getExtension(file.name))">
                  <span class="p-2"> {{ getExtension(file.name) }}</span>
                </b-avatar>
                <div class="mt-1">
                  <span>
                    {{ ellipsis(file.name) }}
                  </span>
                </div>
              </b-card>

              <!-- Hidden Input -->
              <input
                ref="file"
                hidden
                type="file"
                accept=".pdf,.doc, .docx,.ppt, .pptx,.xls, .xlsx"
                @change="onChange()"
              />
            </b-col>
          </b-row>
        </div>

        <div class="mt-1">
          <b-button variant="primary" class="float-right " @click="submitForm">
            <div class="d-flex align-items-center ">
              <b-spinner
                v-show="isLoading"
                class="mr-1"
                variant="light"
                small
              />
              <i
                v-show="!isLoading"
                class="ti ti-device-floppy small-gap-right"
              />
              Simpan
            </div>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BFormInput,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

// eslint-disable-next-line import/no-cycle
import axios from 'axios'
import { avatarText } from '@core/utils/filter'
import { checkAuthorizeRole } from '@/auth/utils'

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BFormInput,
    BSpinner,

    quillEditor,
  },
  data() {
    return {
      // state
      isLoading: false,
      filelist: [],

      // default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,

      // form data
      formErrors: {
        taskTitle: { state: null, message: '' },
        taskDescription: { state: null, message: '' },
      },
      formData: {
        taskTitle: '',
        taskDescription: '',
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    const { breadcrumb } = to.meta

    to.meta.breadcrumb[breadcrumb.length - 2].params = {
      id: to.params.id,
      contentId: to.params.contentId,
    }
    to.meta.breadcrumb[breadcrumb.length - 3].params = { id: to.params.id }
    next()
  },
  methods: {
    avatarText,
    getExtension(filename) {
      return filename.split('.').pop()
    },
    ellipsis(text) {
      if (text.length > 6) {
        return `${text.substring(0, 10)}...${text.substr(
          text.lastIndexOf('.') + 1
        )}`
      }
      return text
    },
    fileType(type) {
      const filetype = {
        pdf: 'danger',
        doc: 'info',
        docx: 'info',
        ppt: 'warning',
        pptx: 'warning',
        xls: 'success',
        xlsx: 'success',
        mp4: 'secondary',
        mkv: 'info',
      }

      return filetype[type]
    },

    onChange() {
      this.filelist = [...this.$refs.file.files]
    },
    remove(i) {
      this.filelist.splice(i, 1)
      const allDropFileContainer = document.querySelectorAll('.drop-file')

      allDropFileContainer.forEach((dropFile) => {
        dropFile.classList.remove('bg-drop-file')
      })
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-drop-file')) {
        event.currentTarget.classList.add('bg-drop-file')
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove('bg-drop-file')
    },
    drop(event) {
      event.preventDefault()
      const droppedFiles = event.dataTransfer.files

      if (droppedFiles.length > 1) {
        event.currentTarget.classList.remove('bg-drop-file')
        this.showToast(
          'danger',
          'XIcon',
          'Gagal',
          'File tidak boleh lebih dari satu'
        )
      } else {
        droppedFiles.forEach((element) => {
          const fileExtension = element.name.split('.').pop()
          const allowedExtension = [
            'pdf',
            'doc',
            'docx',
            'ppt',
            'pptx',
            'xls',
            'xlsx',
            'mp4',
            'mkv',
          ]

          if (allowedExtension.includes(fileExtension)) {
            this.filelist.push(element)
          } else {
            event.currentTarget.classList.remove('bg-drop-file')
            this.showToast('danger', 'XIcon', 'Gagal', 'File tidak didukung')
          }
        })
      }
    },

    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    validateForm() {
      let isValid = true
      if (this.formData.taskTitle === '') {
        this.formErrors.taskTitle = {
          state: false,
          message: 'Nama Tugas tidak boleh kosong',
        }
        isValid = false
      } else {
        this.formErrors.taskTitle = {
          state: null,
          message: '',
        }
      }

      if (this.formData.taskDescription === '') {
        this.formErrors.taskDescription = {
          state: false,
          message: 'Deskripsi Tugas tidak boleh kosong',
        }
        isValid = false
      } else {
        this.formErrors.taskDescription = {
          state: null,
          message: '',
        }
      }

      if (this.formData.taskStartDate === '') {
        this.formErrors.taskStartDate = {
          state: false,
          message: 'Tanggal Mulai Tugas tidak boleh kosong',
        }
        isValid = false
      } else {
        this.formErrors.taskStartDate = {
          state: null,
          message: '',
        }
      }

      if (this.formData.taskEndDate === '') {
        this.formErrors.taskEndDate = {
          state: false,
          message: 'Tanggal Selesai Tugas tidak boleh kosong',
        }
        isValid = false
      } else {
        this.formErrors.taskEndDate = {
          state: null,
          message: '',
        }
      }

      if (!isValid) {
        this.showToast('danger', 'XIcon', 'Error', 'Lengkapi semua data forum terlebih dahulu”')
        this.isLoading = false
      } else {
        this.isLoading = true
      }

      return isValid
    },
    async submitForm() {
      this.isLoading = true
      if (this.validateForm()) {
        // API basic configuration
        const baseUrl = `${process.env.VUE_APP_BASE_API_URL}`
        const url = `${baseUrl}/teachers/classrooms/${this.classroomId}/class-contents/${this.contentId}/forum`
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }

        // Create form data
        const data = new FormData()
        data.append('title', this.formData.taskTitle)
        data.append('description', this.formData.taskDescription)
        data.append('attachment', this.filelist[0])

        // create content task
        await axios
          .post(url, data, config)
          .then((response) => {
            // stop loading
            this.isLoading = false

            // show success toast
            this.showToast(
              'success',
              'CheckIcon',
              'Berhasil',
              'Forum berhasil ditambah'
            )

            this.$router.push({
              name: checkAuthorizeRole('classroom-detail-forum'),
              params: {
                id: this.classroomId,
                contentId: this.contentId,
              },
            })
          })
          .catch((error) => {
            this.isLoading = false

            // show success toast
            this.showToast(
              'danger',
              'XIcon',
              'Gagal',
              error.response.data.message
            )
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.line-height-small {
  line-height: 1.5rem;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.preview-material {
  max-height: 200px;
  object-fit: fill;
}

.preview-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  font-size: 2rem;
}

.vertical-scrollable > .row {
  position: relative;
  height: calc(100vh - 330px);
  width: 100%;
  overflow-y: scroll;
}

.active-material-type {
  border-width: 2px !important;
}

.drop-file {
  cursor: pointer;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 2px dashed #d3d3d3 !important;
  border-radius: 0.428rem !important;
}
.card-material-type {
  cursor: pointer;
}

.remove-button-x {
  padding: 10px 10px;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}

.bg-drop-file {
  background-color: #c9ffe7 !important;
}
</style>
